<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">Maksusuoritukset</div>

    <v-card class="mt-2">
      <v-container>
        <!-- Search by dates -->
        <search-by-dates
          v-show="tab === 0"
          :searchByItems="searchByItems"
          defaultSearchBy="paidAmounts.date"
        ></search-by-dates>

        <v-row dense> </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-text-field
              v-model="tenant"
              label="Vuokralainen"
              dense
              outlined
              hide-details
              @keyup.enter="getDataFromApi()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-autocomplete
              v-model="apartment"
              :items="activeApartments"
              :item-text="fullAddress"
              label="Vuokrakohde"
              item-value="_id"
              no-data-text="Vuokrakohteen osoite"
              outlined
              dense
              clearable
              :search-input.sync="apartmentSearchInput"
              @input.native="getApartments"
              hide-details
              @keyup.enter="getDataFromApi()"
            >
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row dense class="mt-1">
          <v-col cols="6" md="4">
            <v-btn class="mr-5 mb-2" color="info" @click.prevent="getDataFromApi()">Etsi</v-btn>
            <v-btn class="mb-2" color="error" outlined @click="resetForms()">Tyhjennä</v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
        :search="search"
        :headers="headers"
        :items="payments"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <!-- Apartment Address -->
        <template #[`item.address`]="{ item }">
          <span>{{ item.address }}</span>
          <span v-if="item.apartmentNumber">, {{ item.apartmentNumber }} </span>
        </template>

        <!--invoiceNumber -->
        <template #[`item.invoiceNumber`]="{ item }">
          <span># {{ item.invoiceNumber }}</span>
        </template>

        <!--date -->
        <template #[`item.date`]="{ item }">
          <span class="font-weight-bold">{{ formatDate(item.date) }}</span>
        </template>

        <!--invoicetype -->
        <template #[`item.invoiceType`]="{ item }">
          <span>{{ formatInvoiceType(item.invoiceType) }}</span>
        </template>

        <!--amount -->
        <template #[`item.amount`]="{ item }">
          <span
            :class="item.amount < 0 ? 'error--text' : 'success--text'"
            class="font-weight-bold"
            >{{ formatCurrency(item.amount) }}</span
          >
        </template>

        <template #no-data>
          <h2>Ei maksusuorituksia</h2>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import SearchByDates from "../../components/Invoice/SearchByDates.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import mixins from "@/mixins/mixins";
import { debounce } from "../../utils/helpers";
import globalValues from "@/configs/globalValues";

export default {
  title: "Maksusuoritukset",
  mixins: [mixins],

  components: {
    SearchByDates,
  },

  data: () => ({
    loading: true,
    options: {},
    page: 1,
    search: "",
    apartmentInput: "",
    apartmentSearchInput: "",
    tenant: "",
    apartment: null,
    headers: [
      {
        text: "Osoite",
        value: "address",
      },
      {
        text: "Päävuokralainen",
        value: "tenant",
      },
      {
        text: "Sopimusnro.",
        value: "contractNumber",
      },
      {
        text: "Laskutyyppi",
        value: "invoiceType",
      },
      {
        text: "Lasku",
        value: "invoiceNumber",
      },
      {
        text: "Summa",
        value: "amount",
      },
      {
        text: "Kirjauspäivä",
        value: "date",
      },
    ],
    searchByItems: [{ text: "Kirjauspäivä", val: "paidAmounts.date" }],
    globalValues,
  }),

  created() {
    this.resetSearchByDates("paidAmounts.date");
  },

  mounted() {
    if (this.$route.query.tab) {
      this.tab = Number(this.$route.query.tab);
    }

    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }
  },

  computed: {
    ...mapState("invoice", ["totalLength", "payments", "searchByDates"]),
    ...mapState("account", ["currentUser"]),
    ...mapState("apartment", ["activeApartments"]),

    tab: {
      get() {
        return this.$store.state.browsehistory.tab;
      },
      set(val) {
        this.$store.state.browsehistory.tab = val;
      },
    },
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },

    apartmentInput: debounce(function (newVal) {
      this.searchApartment(`/api/v1/apartment/search-apartment?search=${newVal}&searchAll=true`);
    }, 1000),

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("invoice", ["getAllPayments"]),
    ...mapMutations("invoice", ["resetSearchByDates"]),
    ...mapActions("apartment", ["searchApartment"]),

    async getDataFromApi() {
      this.loading = true;
      const url = this.getUrl();
      await this.getAllPayments({
        url,
        data: { tenantName: this.tenant, apartment: this.apartment },
      });
      this.loading = false;
    },

    getUrl() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const search = this.search.trim().toLowerCase();
      const start = this.searchByDates.startDate;
      const end = this.searchByDates.endDate;
      const searchBy = this.searchByDates.searchBy;

      const additionalQuery = `?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}&startDate=${start}&endDate=${end}&searchBy=${searchBy}`;
      const url = "/api/v1/payments" + additionalQuery;
      return url;
    },

    async getApartments(event) {
      this.apartmentInput = event.target.value;
    },

    fullAddress(item) {
      return item.apartmentNumber ? item.address + ", " + item.apartmentNumber : item.address;
    },

    formatInvoiceType(type) {
      if (type === "refund") {
        return "Hyvityslasku";
      } else {
        return "Lasku";
      }
    },

    resetForms() {
      this.resetSearchByDates("paidAmounts.date");
      this.tenant = "";
      this.apartment = null;
      this.search = "";
      this.getDataFromApi();
    },
  },
};
</script>

<style scoped></style>
